  <template>
      <Layout>
      </Layout>
  </template>
  <script>
    import test from "@/app.config.json";
    // eslint-disable-next-line
    import { ZoomMtg } from '@zoomus/websdk';
    export default {

      components: {
      
      },data() {
        return {
          baseUrl:test.baseUrl,user:[],
          // sdkKey:'2UOFpn5sXSoRqa8HQbEm64OJN9wdmG4lCWUG',
          // sdkSecret:'jWcgIl29Aff9Blas5GpRAkwvNHLn5T60SQtv',
          sdkKey:'13low2r1WXOn1btGB38w8yDqqPjgSqCLfGk9',
          sdkSecret:'nOlZqabjptUxkEFA7VZT1lpFGth9fpe86Y4q',
          meetingNumber:"",
          passWord:"",
          userName:'',
          userEmail:'',
          role:1,
          leaveUrl:'https://europeandesignstudio.com',
          registrantToken: '',userId:''
        };
      },created:function(){
        window.addEventListener('beforeunload', this.confirm_leaving)
        this.user = JSON.parse(localStorage.getItem('user'));
        this.meetingNumber=this.$route.params.id;
        this.passWord=this.$route.params.pass;
        this.userId= this.$route.params.userid;
        this.userName= this.user.fullname;
        this.userEmail= this.user.email;
        this.role= (this.user.id==this.userId?1:2);
        this.joinMeeting(this);
        console.log(this.role)
      },methods: {
         /* eslint-disable */
         joinMeeting(payload)
         {
          ZoomMtg.setZoomJSLib('https://source.zoom.us/lib','/av');
          ZoomMtg.preLoadWasm();
          ZoomMtg.prepareJssdk();
          ZoomMtg.prepareWebSDK();
          ZoomMtg.generateSDKSignature({
            sdkKey:payload.sdkKey,
            sdkSecret:payload.sdkSecret,
            meetingNumber:payload.meetingNumber,
            role:payload.role,
            success:function(signature){
               document.getElementById('zmmtg-root').style.display = 'block'
               ZoomMtg.init({
                 leaveUrl:payload.leaveUrl,
                 isSupportAV: true,
                 //SharedArrayBuffer: true,
                 disableCORP: !window.crossOriginIsolated, // default true
                 //disablePreview: true,
                 success:function(data) {
                   console.log(data)
                   ZoomMtg.join({
                      meetingNumber:payload.meetingNumber,
                      passWord:payload.passWord,
                      sdkKey:payload.sdkKey,
                      userName:payload.userName,
                      userEmail:payload.userEmail,
                      signature:signature.result,
                      //tk:this.registrantToken,
                      success:function(data){
                         console.log(data);
                      },error:function(error){
                        console.log('---join error-->',error);
                      }
                   })
                 },error:function(error){
                  console.log('---Init error-->',error);
                 }
               })
            },error:function(error){
              console.log('---son error-->',error)
            }
          })
        },confirm_leaving () 
        {
          if(this.role==1)
          {
              ZoomMtg.endMeeting();
          }
          if(this.role==2)
          {
              ZoomMtg.leaveMeeting();
          }
            // const unsaved_changes_warning = " ";
            // evt.returnValue = unsaved_changes_warning; 
             return this.role;

      }
      }
    };
  </script>